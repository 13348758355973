@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

html {
  scroll-behavior: smooth;
}

.row {
  max-width: 1400px;
  margin: 0 auto;
}

.blue {
  color: #5b69a9;
}

.black {
  color: #243e63;
}

a {
  text-decoration: none;
  color: white;
}

/* Nav */

nav {
  width: 100%;
  background-color: #5b69a9;
  color: white;
  height: 100px;
  display: flex;
  align-items: center;
}

.nav__row {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.nav__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 36px;
}

.nav__menu {
  font-size: 36px;
  display: flex;
  cursor: pointer;
  transition: all 500ms ease;
}

.nav__logo > h1 > a {
  font-size: 40px;
  font-family: "Quicksand" !important;
}

.nav__cta > h1 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  background-color: white;
  color: #5b69a9;
  padding: 8px 12px;
  border-radius: 10px;
  cursor: not-allowed;
}

.nav__menu:hover {
  color: #e3e3e3;
  transform: scale(1.05);
}

.nav__menu:active {
  transform: scale(0.98);
}

.nav__sidebar {
  background-color: rgb(91, 105, 169, 0.9);
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.nav__sidebar > ul {
  list-style: none;
  margin-top: 128px;
}

.nav__sidebar > ul > li {
  font-size: 56px;
  font-weight: 500;
  margin-top: 16px;
  cursor: pointer;
  transition: all 500ms ease;
}

.nav__sidebar > ul > li:hover {
  color: #e3e3e3;
  transform: scale(1.02);
}

.nav__sidebar > ul > li:active {
  transform: scale(0.98);
}

.sidebar__close {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 56px;
  cursor: pointer;
  transition: all 500ms ease;
  color: white;
}

.sidebar__close:hover {
  color: #e3e3e3;
  transform: scale(1.05);
}

.sidebar__close:active {
  transform: scale(0.98);
}
/* Landing */

.landing {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
}

.landing__container {
  display: flex;
  justify-content: center;
  gap: 56px;
  align-items: center;
  margin-bottom: 100px;
}

.landing__info {
  max-width: 600px;
}

.landing__info > h1 {
  color: #243e63;
  font-size: 56px;
  line-height: 1.3;
}

.landing__info > p {
  color: #243e63;
  font-size: 28px;
  margin-top: 16px;
}

.landing__img--container {
  display: flex;
}

.landing__img {
  border-radius: 50%;
  width: 456px;
}

/* Why Us */

.why {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
}

.why__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.why__title {
  text-align: center;
}

.why__title > h2 {
  color: #3a4678;
  font-size: 20px;
}

.why__title > h1 {
  color: #243e63;
  font-size: 56px;
}

.why__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 960px;
  row-gap: 16px;
  margin-top: 24px;
}

.why__item {
  width: 300px;
  height: 300px;
  background-color: #5b69a9;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.why__item > h1 {
  text-align: center;
  color: white;
  line-height: 1.3;
  font-weight: 600;
}

.why__item > p {
  color: #f1f1f1;
  font-weight: 500;
}

/* Hocl */

.hocl {
  height: 100vh;
  display: flex;
  align-items: center;
}

.hocl__container {
  display: flex;
  gap: 56px;
}

.hocl__img--container {
  display: flex;
  align-items: center;
}

.hocl__img {
  width: 600px;
}

.hocl__info {
  max-width: 620px;
}
.hocl__info > h2 {
  color: #3a4678;
  font-size: 20px;
  margin-bottom: 4px;
}
.hocl__info > h1 {
  color: #243e63;
  font-size: 54px;
  line-height: 1.2;
}
.hocl__info > p {
  margin-top: 20px;
  color: #243e63;
  font-size: 20px;
  font-weight: 500;
}

/* Results */

.results {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
}
.results__container {
  margin-bottom: 76px;
}
.results__title {
  text-align: center;
  color: #243e63;
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 32px;
}
.results__slider {
  width: 900px;
}

.results__img {
  width: 100%;
  border-radius: 20px;
  padding: 0 8px;
}

.results__arrow-prev {
  color: #000;
  border: 1px solid #dddddd;
  background-color: #fff;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 28px;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  transition: all 300ms ease;
  cursor: pointer;
}
.results__arrow-next {
  color: #000;
  border: 1px solid #dddddd;
  background-color: #fff;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 28px;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  transition: all 300ms ease;
  cursor: pointer;
}

.results__arrow-next:hover,
.results__arrow-prev:hover {
  filter: brightness(0.95);
}
/* Footer */

.footer {
  background-color: #5b69a9;
}

.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 32px 0;
}

.footer__logo > h1 > a {
  font-size: 40px;
  font-family: "Quicksand";
  cursor: pointer;
}
.footer__links > ul {
  list-style: none;
  display: flex;
}

.footer__links > ul > li {
  margin: 10px 16px;
  font-size: 24px;
  font-weight: 300;
  cursor: pointer;
}

.footer__links > ul > li:hover {
  color: #e3e3e3;
}

.footer__copyright > h1 {
  font-size: 18px;
  font-weight: 300;
}

@media (max-width: 1350px) {
  .hocl__img {
    width: 500px;
  }
  .hocl__info {
    max-width: 450px;
  }
  .hocl__info > h2 {
    font-size: 16px;
  }
  .hocl__info > h1 {
    font-size: 36px;
  }
  .hocl__info > p {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {
  .landing__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landing__info {
    max-width: 600px;
  }

  .landing__info > h1 {
    font-size: 56px;
  }

  .landing__info > p {
    font-size: 30px;
  }

  .landing__img--container {
    display: none;
  }

  .landing__img {
    border-radius: 50%;
    width: 300px;
  }
  .hocl__container {
    gap: 0;
  }

  .hocl__img {
    display: none;
  }

  .hocl__info {
    max-width: 700px;
  }
  .hocl__info > h2 {
    font-size: 20px;
  }
  .hocl__info > h1 {
    font-size: 56px;
    line-height: 1.3;
  }
  .hocl__info > p {
    font-size: 24px;
    margin-top: 16px;
  }
}

@media (max-width: 1000px) {
  .why {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
  }

  .why__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .why__title {
    text-align: center;
  }

  .why__title > h2 {
    color: #3a4678;
    font-size: 20px;
  }

  .why__title > h1 {
    color: #243e63;
    font-size: 56px;
    max-width: 600px;
    line-height: 1.3;
  }

  .why__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 600px;
    row-gap: 16px;
    margin-top: 24px;
  }

  .why__item {
    width: 275px;
    height: 175px;
    background-color: #5b69a9;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .why__item > h1 {
    text-align: center;
    color: white;
    line-height: 1.3;
    font-weight: 600;
    font-size: 24px;
  }

  .why__item > p {
    display: none;
  }
  .results__slider {
    width: 600px;
  }
}

@media (max-width: 800px) {
  .hocl__info {
    max-width: 600px;
  }
  .hocl__info > h2 {
    font-size: 20px;
  }
  .hocl__info > h1 {
    font-size: 56px;
    line-height: 1.3;
  }
  .hocl__info > p {
    font-size: 20px;
    margin-top: 16px;
  }
  .footer__logo > h1 > a {
    font-size: 30px;
  }

  .footer__links > ul > li {
    font-size: 20px;
  }

  .footer__links > ul > li:hover {
    color: #e3e3e3;
  }

  .footer__copyright > h1 {
    font-size: 16px;
  }
}

@media (max-width: 680px) {
  .landing__info {
    max-width: 320px;
  }

  .landing__info > h1 {
    font-size: 35px;
  }

  .landing__info > p {
    font-size: 24px;
  }

  .why__title {
    text-align: center;
  }

  .why__title > h2 {
    color: #3a4678;
    font-size: 14px;
  }

  .why__title > h1 {
    color: #243e63;
    font-size: 35px;
    max-width: 320px;
  }

  .why__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 370px;
    row-gap: 8px;
    margin-top: 24px;
  }

  .why__item {
    width: 175px;
    height: 125px;
    background-color: #5b69a9;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .why__item > h1 {
    text-align: center;
    color: white;
    line-height: 1.3;
    font-weight: 600;
    font-size: 16px;
  }

  .why__item > p {
    display: none;
  }

  .hocl__info {
    max-width: 320px;
  }
  .hocl__info > h2 {
    font-size: 14px;
  }
  .hocl__info > h1 {
    font-size: 28px;
    line-height: 1.3;
  }
  .hocl__info > p {
    font-size: 14px;
    margin-top: 16px;
  }
  .results__slider {
    width: 300px;
  }
  .results__title {
    font-size: 22px;
  }
  .results__arrow-prev {
    height: 30px;
    width: 30px;
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    left: -8px;
  }
  .results__arrow-next {
    height: 30px;
    width: 30px;
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    right: -8px;
  }
}

@media (max-width: 600px) {
  nav {
    width: 100%;
    background-color: #5b69a9;
    color: white;
    height: 100px;
    display: flex;
    align-items: center;
  }

  .nav__row {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
  }

  .nav__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 36px;
  }

  .nav__menu {
    font-size: 36px;
    display: flex;
    cursor: pointer;
    transition: all 500ms ease;
  }

  .nav__logo > h1 > a {
    font-size: 30px;
    font-family: "Quicksand" !important;
  }

  .nav__cta > h1 {
    font-size: 14px;
  }

  .nav__sidebar > ul > li {
    font-size: 36px;
  }

  .sidebar__close {
    font-size: 40px;
  }
}
